import actionTypes from "../actionTypes";

export const crearAnuncioValidateInit = (language, data) => {
	return {
		type: actionTypes.CREAR_ANUNCIO_VALIDATE_INIT,
		payload: {
			language,
			data,
		},
	};
};

export const crearAnuncioInit = (language, data) => {
	return {
		type: actionTypes.CREAR_ANUNCIO_INIT,
		payload: {
			language,
			data,
		},
	};
};

export const crearAnuncioReset = () => {
	return {
		type: actionTypes.CREAR_ANUNCIO_RESET,
	};
};

import React, { useEffect, useState } from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { connect } from "react-redux";
import { crearAnuncioValidateInit, crearAnuncioInit, crearAnuncioReset } from "../redux/crearAnuncio/actions";
import { appUICrearAnuncioPageBack, appRedirect } from "../redux/app/actions";
import { useIntl, Link, navigate } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import Tools from "../classes/tools";

const CrearAnuncio = ({
	authResolved,
	loggedIn,
	boot,
	crearAnuncioPage,
	crearAnuncioReady,
	crearAnuncioDone,
	appUICrearAnuncioPageBack,
	crearAnuncioValidateInit,
	crearAnuncioInit,
	crearAnuncioReset,
	usuario,
	appRedirect,
	s,
}) => {
	const intl = useIntl();

	// estado local del formulario

	const [tabla, setTabla] = useState("");
	const [tablaOtra, setTablaOtra] = useState("");
	const [title, setTitle] = useState("");
	const [descripcion, setDescripcion] = useState("");
	const [medidas, setMedidas] = useState("");
	const [operacion, setOperacion] = useState(1);
	const [precioAlquiler, setPrecioAlquiler] = useState("");
	const [precioVenta, setPrecioVenta] = useState("");
	const [lat, setLat] = useState("");
	const [long, setLong] = useState("");
	const [geoDescripcion, setGeoDescripcion] = useState("");
	const [puntoEncuentro, setPuntoEncuentro] = useState("");
	const [imagenes, setImagenes] = useState([]);

	// se dispara ni bien se entra a la pagina

	useEffect(() => {
		crearAnuncioReset();
		setTabla("");
		setTablaOtra("");
		setTitle("");
		setDescripcion("");
		setMedidas("");
		setOperacion(1);
		setPrecioAlquiler("");
		setPrecioVenta("");
		setLat("");
		setLong("");
		setGeoDescripcion("");
		setPuntoEncuentro("");
		setImagenes([]);
	}, []);

	// se dispara cuando ya se sabe que el usuario no esta logueado

	useEffect(() => {
		if (authResolved && !loggedIn) appRedirect({ to: "/" });
	}, [loggedIn, authResolved]);

	const handleSetTabla = (tabla) => {
		if (tablaOtra) setTablaOtra("");
		setTabla(tabla);
	};

	const handleSetTablaOtra = (tablaOtra) => {
		if (tabla) setTabla("");
		setTablaOtra(tablaOtra);
	};

	// se dispara cuando crearAnuncioReady del store es true, es decir se puede crear el anuncio

	useEffect(() => {
		if (crearAnuncioReady) {
			crearAnuncioInit(intl.locale, {
				token: usuario.token,
				tabla,
				tabla_otra: tablaOtra,
				title,
				descripcion,
				medidas,
				operacion,
				precio_alquiler: precioAlquiler,
				precio_venta: precioVenta,
				lat,
				long,
				geo_descripcion: geoDescripcion,
				punto_encuentro: puntoEncuentro,
				imagenes,
			});
		}
	}, [crearAnuncioReady]);

	// se dispara cuando el anuncio fue creado

	useEffect(() => {
		if (crearAnuncioDone) {
			crearAnuncioReset();
			setTabla("");
			setTablaOtra("");
			setTitle("");
			setDescripcion("");
			setMedidas("");
			setOperacion(1);
			setPrecioAlquiler("");
			setPrecioVenta("");
			setLat("");
			setLong("");
			setGeoDescripcion("");
			setPuntoEncuentro("");
			setImagenes([]);
		}
	}, [crearAnuncioDone]);

	const back = () => {
		appUICrearAnuncioPageBack();
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		switch (crearAnuncioPage) {
			case 1:
				crearAnuncioValidateInit(intl.locale, {
					tabla,
					tabla_otra: tablaOtra,
				});
				break;
			case 2:
				crearAnuncioValidateInit(intl.locale, {
					title,
					descripcion,
					medidas,
				});
				break;
			case 3:
				crearAnuncioValidateInit(intl.locale, {
					operacion: operacion,
					precio_alquiler: precioAlquiler,
					precio_venta: precioVenta,
				});
				break;
			case 4:
				crearAnuncioValidateInit(intl.locale, {
					lat: lat,
					long: long,
					geo_descripcion: geoDescripcion,
					punto_encuentro: puntoEncuentro,
				});
				break;
			case 5:
				crearAnuncioValidateInit(intl.locale, {
					imagenes,
				});
				break;
		}
	};

	var imagenReader = null;

	try {
		imagenReader = new FileReader(); // sino, webpack o quien sea no encuentra la clase
		imagenReader.addEventListener(
			"load",
			() => {
				const img = new Image();
				img.onload = () => {
					let w = 800;
					let h = 800;
					if (img.width > img.height) {
						h = w / (img.width / img.height);
					} else {
						w = h / (img.height / img.width);
					}
					const canvas = document.createElement("canvas");
					canvas.width = w;
					canvas.height = h;
					const ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0, w, h);
					setImagenes([...imagenes, canvas.toDataURL("image/jpeg")]);
				};
				img.src = imagenReader.result;
			},
			false
		);
	} catch {}

	const handleImageAdd = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			const allowedTypes = ["image/jpeg", "image/png"];
			if (allowedTypes.indexOf(file.type) > -1) {
				imagenReader.readAsDataURL(e.target.files[0]);
			}
		}
	};

	const borrarImagen = (index) => {
		const newImagenes = [...imagenes];
		newImagenes.splice(index, 1);
		setImagenes(newImagenes);
	};

	const query = useStaticQuery(
		graphql`
			{
				boardPost1: file(relativePath: { eq: "board-post-01.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				boardPost2: file(relativePath: { eq: "board-post-02.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				boardPost3: file(relativePath: { eq: "board-post-03.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				boardPost4: file(relativePath: { eq: "board-post-04.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				boardPost5: file(relativePath: { eq: "board-post-05.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`
	);

	const onPlaceSelected = (place) => {
		if (place.geometry?.location?.lat && place.geometry?.location?.lng && place.formatted_address) {
			setLat(place.geometry.location.lat());
			setLong(place.geometry.location.lng());
			setGeoDescripcion(place.formatted_address);
		}
	};

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="board-post">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s.crearanuncio?.title)} />
					{loggedIn && authResolved && (
						<main className="page form-page lightgray-bg-01">
							{/* STEP 01 */}
							{crearAnuncioPage === 1 && (
								<div className="steps-wrapper">
									<article className="form-box box">
										<div className="wrapper">
											<ul className="breadcrumbs">
												<li className="link">
													<Link to="/" className="ltblue-text-02">
														Home
													</Link>
												</li>
												<li className="active">
													<p className="lightgray-text-07">Crear anuncio</p>
												</li>
											</ul>
											<h4 className="black-text-03 prequel-font">{Tools.convertString(s.crearanuncio?.tabla_tipo)}</h4>
											<ul className="steps-bullets">
												<li>
													<span className="lightgray-bg-03 white-text active"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text"></span>
												</li>
											</ul>
											<form onSubmit={handleSubmit}>
												<fieldset>
													<ul className="board-type">
														{boot.tablas.map((t) => (
															<li key={t.id}>
																<label className="black-text-01">
																	<input
																		className="input black-text-03"
																		type="radio"
																		name="board-type"
																		checked={t.id === tabla}
																		onChange={handleSetTabla.bind(null, t.id)}
																	/>
																	<span className="white-bg">
																		<img src={t.imagen.url} alt={t.title} />
																	</span>
																</label>
																<p className="align-center black-text-01">{t.title}</p>
															</li>
														))}
													</ul>
													<label className="black-text-01" htmlFor="another-board-type">
														{Tools.convertString(s.crearanuncio?.tabla_otra)}
														<input
															className="input lightgray-bg-01 black-text-03"
															placeholder={Tools.convertString(s.crearanuncio?.tabla_otra_placeholder)}
															type="text"
															value={tablaOtra}
															onChange={(e) => handleSetTablaOtra(e.target.value)}
														/>
													</label>
												</fieldset>
												<div className="button-wrapper lightgray-bg-01">
													<button className="button black-bg-03 white-text">
														{Tools.convertString(s.crearanuncio?.siguiente)}
													</button>
												</div>
											</form>
										</div>
									</article>

									<article className="form-img show-lg">
										<Img fluid={query.boardPost1.childImageSharp.fluid} alt={``} />
									</article>
								</div>
							)}
							{/* /STEP 01 */}

							{/* STEP 02 */}
							{crearAnuncioPage === 2 && (
								<div className="steps-wrapper lightgray-bg-01">
									<article className="form-box box">
										<div className="wrapper">
											<a className="section-back prequel-font black-text-03" onClick={back}>
												{Tools.convertString(s.crearanuncio?.tabla_tipo)}
											</a>
											<h4 className="black-text-03 prequel-font">
												{Tools.convertString(s.crearanuncio?.info_tecnica)}
											</h4>
											<ul className="steps-bullets">
												<li>
													<span className="lightgray-bg-03 white-text seen"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text active"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text"></span>
												</li>
											</ul>
											<form onSubmit={handleSubmit}>
												<fieldset>
													<label className="black-text-01">
														{Tools.convertString(s.crearanuncio?.titulo)}*
														<input
															className="input lightgray-bg-01 black-text-03"
															placeholder={Tools.convertString(s.crearanuncio?.titulo_placeholder)}
															type="text"
															value={title}
															onChange={(e) => setTitle(e.target.value)}
														/>
													</label>
													<label className="black-text-01">
														{Tools.convertString(s.crearanuncio?.descripcion)}*
														<textarea
															className="input lightgray-bg-01 black-text-03"
															placeholder={Tools.convertString(s.crearanuncio?.descripcion_placeholder)}
															onChange={(e) => setDescripcion(e.target.value)}
															value={descripcion}
														></textarea>
													</label>
													<label className="black-text-01">
														{Tools.convertString(s.crearanuncio?.medidas)}*
														<input
															className="input lightgray-bg-01 black-text-03"
															placeholder={Tools.convertString(s.crearanuncio?.medidas_placeholder)}
															type="text"
															value={medidas}
															onChange={(e) => setMedidas(e.target.value)}
														/>
													</label>
												</fieldset>
												<div className="button-wrapper lightgray-bg-01">
													<button className="button black-bg-03 white-text">
														{Tools.convertString(s.crearanuncio?.siguiente)}
													</button>
												</div>
											</form>
										</div>
									</article>

									<article className="form-img show-lg">
										<Img fluid={query.boardPost2.childImageSharp.fluid} alt={``} />
									</article>
								</div>
							)}
							{/* /STEP 02 */}

							{/* STEP 03 */}
							{crearAnuncioPage === 3 && (
								<div className="steps-wrapper lightgray-bg-01">
									<article className="form-box box">
										<div className="wrapper">
											<a className="section-back prequel-font black-text-03" onClick={back}>
												{Tools.convertString(s.crearanuncio?.info_tecnica)}
											</a>
											<h4 className="black-text-03 prequel-font">{Tools.convertString(s.crearanuncio?.precio)}</h4>
											<ul className="steps-bullets">
												<li>
													<span className="lightgray-bg-03 white-text seen"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text seen"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text active"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text"></span>
												</li>
											</ul>
											<form onSubmit={handleSubmit}>
												<fieldset>
													<label className="black-text-01">
														{Tools.convertString(s.crearanuncio?.operacion)}
														<div className="buttonSet">
															<button
																type="button"
																className={operacion === 1 && "active"}
																onClick={() => setOperacion(1)}
															>
																{Tools.convertString(s.crearanuncio?.alquiler)}
															</button>
															<button
																type="button"
																className={operacion === 2 && "active"}
																onClick={() => setOperacion(2)}
															>
																{Tools.convertString(s.crearanuncio?.venta)}
															</button>
															<button
																type="button"
																className={operacion === 3 && "active"}
																onClick={() => setOperacion(3)}
															>
																{Tools.convertString(s.crearanuncio?.alquilerventa)}
															</button>
														</div>
													</label>
												</fieldset>
												{(operacion === 1 || operacion === 3) && (
													<fieldset>
														<label className="black-text-01">
															{Tools.convertString(s.crearanuncio?.precio_hora)}
															<input
																className="input lightgray-bg-01 black-text-03"
																placeholder={Tools.convertString(s.crearanuncio?.precio_hora_placeholder)}
																type="number"
																value={precioAlquiler}
																onChange={(e) => setPrecioAlquiler(e.target.value)}
															/>
															<small className="lightgray-text-04">
																{Tools.convertString(s.crearanuncio?.precio_hora_texto)}
															</small>
														</label>
													</fieldset>
												)}
												{(operacion === 2 || operacion === 3) && (
													<fieldset>
														<label className="black-text-01">
															{Tools.convertString(s.crearanuncio?.precio_venta)}
															<input
																className="input lightgray-bg-01 black-text-03"
																placeholder={Tools.convertString(s.crearanuncio?.precio_venta_placeholder)}
																type="number"
																value={precioVenta}
																onChange={(e) => setPrecioVenta(e.target.value)}
															/>
															<small className="lightgray-text-04">
																{Tools.convertString(s.crearanuncio?.precio_venta_texto)}
															</small>
														</label>
													</fieldset>
												)}
												<div className="button-wrapper lightgray-bg-01">
													<button className="button black-bg-03 white-text">
														{Tools.convertString(s.crearanuncio?.siguiente)}
													</button>
												</div>
											</form>
										</div>
									</article>

									<article className="form-img show-lg">
										<Img fluid={query.boardPost3.childImageSharp.fluid} alt={``} />
									</article>
								</div>
							)}
							{/* /STEP 03 */}

							{/* STEP 04 */}
							{crearAnuncioPage === 4 && (
								<div className="steps-wrapper lightgray-bg-01">
									<article className="form-box box">
										<div className="wrapper">
											<a className="section-back prequel-font black-text-03" onClick={back}>
												{Tools.convertString(s.crearanuncio?.precio)}
											</a>
											<h4 className="black-text-03 prequel-font">{Tools.convertString(s.crearanuncio?.ubicacion)}</h4>
											<ul className="steps-bullets">
												<li>
													<span className="lightgray-bg-03 white-text seen"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text seen"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text seen"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text active"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text"></span>
												</li>
											</ul>
											<form onSubmit={handleSubmit}>
												<fieldset>
													<label className="black-text-01" htmlFor="location-ubicacion">
														{Tools.convertString(s.crearanuncio?.ubicacion)}*
														<ReactGoogleAutocomplete
															className="input lightgray-bg-01 black-text-03"
															onPlaceSelected={onPlaceSelected}
															placeholder={Tools.convertString(s.crearanuncio?.ubicacion_placeholder)}
															defaultValue={geoDescripcion}
														/>
													</label>
													<label className="black-text-01" htmlFor="location-meeting">
														{Tools.convertString(s.crearanuncio?.punto_encuentro)}*
														<textarea
															className="input lightgray-bg-01 black-text-03"
															placeholder={Tools.convertString(s.crearanuncio?.punto_encuentro_placeholder)}
															onChange={(e) => setPuntoEncuentro(e.target.value)}
															value={puntoEncuentro}
														></textarea>
														<small className="lightgray-text-04">
															{Tools.convertString(s.crearanuncio?.punto_encuentro_texto)}
														</small>
													</label>
												</fieldset>
												<div className="button-wrapper lightgray-bg-01">
													<button className="button black-bg-03 white-text">
														{Tools.convertString(s.crearanuncio?.siguiente)}
													</button>
												</div>
											</form>
										</div>
									</article>

									<article className="form-img show-lg">
										<Img fluid={query.boardPost4.childImageSharp.fluid} alt={``} />
									</article>
								</div>
							)}
							{/* /STEP 04 */}

							{/* STEP 05 */}
							{crearAnuncioPage === 5 && (
								<div className="steps-wrapper lightgray-bg-01">
									<article className="form-box box">
										<div className="wrapper">
											<a className="section-back prequel-font black-text-03" onClick={back}>
												{Tools.convertString(s.crearanuncio?.ubicacion)}
											</a>
											<h4 className="black-text-03 prequel-font">{Tools.convertString(s.crearanuncio?.fotos)}</h4>
											<ul className="steps-bullets">
												<li>
													<span className="lightgray-bg-03 white-text seen"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text seen"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text seen"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text seen"></span>
												</li>
												<li>
													<span className="lightgray-bg-03 white-text active"></span>
												</li>
											</ul>
											<form onSubmit={handleSubmit}>
												<fieldset>
													<span className="wrapper-imgs">
														{imagenes.map((imagen, index) => {
															return (
																<div key={index}>
																	<img className="profile-img" src={imagen} />
																	<a
																		className="black-bg-01 close-button delete-img"
																		onClick={() => borrarImagen(index)}
																	></a>
																</div>
															);
														})}
														<label className="black-text-01 file-upload" htmlFor="crearAnuncioImagen">
															<img className="profile-img" src="/img/upload-icon.svg" alt="Tu imagen" />
															<input
																type="file"
																className="file"
																size="29"
																length="4096"
																name="crearAnuncioImagen"
																id="crearAnuncioImagen"
																onChange={handleImageAdd}
															/>
														</label>
													</span>
													<span className="lightgray-text-06">
														{Tools.convertString(s.crearanuncio?.fotos_aclaracion)}
													</span>
												</fieldset>
												<div className="button-wrapper lightgray-bg-01">
													<button className="button black-bg-03 white-text">
														{Tools.convertString(s.crearanuncio?.publicar)}
													</button>
												</div>
											</form>
										</div>
									</article>

									<article className="form-img show-lg">
										<Img fluid={query.boardPost5.childImageSharp.fluid} alt={``} />
									</article>
								</div>
							)}
							{/* /STEP 05 */}

							<Footer />
						</main>
					)}
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	const { resolved: authResolved, loggedIn, usuario } = state.auth;
	const { boot } = state.app;
	const { crearAnuncioPage } = state.app.uiSubpages;
	const { crearAnuncioReady, crearAnuncioDone } = state.app.events;
	return {
		authResolved,
		loggedIn,
		usuario,
		boot,
		crearAnuncioPage,
		crearAnuncioReady,
		crearAnuncioDone,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	crearAnuncioValidateInit,
	crearAnuncioInit,
	crearAnuncioReset,
	appUICrearAnuncioPageBack,
	appRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrearAnuncio);
